<template>
  <div class="member-shop-order-table">
    <BaseTable :data="displayData">
      <BaseElTableColumn label="訂閱狀態" prop="status" align="center">
        <template slot-scope="scope">
          <Tag :type="selectedStatus(get(scope.row, 'status'), 'tagType')">{{ selectedStatus(get(scope.row, 'status'), 'label') }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="扣款失敗紀錄" prop="failRecord" align="center">
        <template slot-scope="scope">
          <p :class="{'text-danger' : scope.row.failRecord}">{{ scope.row.failRecord || '-' }}</p>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="訂閱方案名稱" prop="planName" align="center" />
      <BaseElTableColumn label="訂閱品項" prop="combineName" align="center" />
      <BaseElTableColumn label="會員姓名" prop="name" align="center" />
      <BaseElTableColumn label="會員手機" prop="phone" align="center" />
      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <BaseElButton class="text-primary-100 underline" type="text" @click="onRowView(scope.row)">查看</BaseElButton>
        </template>
      </BaseElTableColumn>
    </BaseTable>
  </div>
</template>

<script>
import Tag from '@/components/Tag/Tag.vue'
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router/composables'
import { get } from 'lodash'
import { subscribeOrderStatusConfig } from '@/config/subscribe'

export default defineComponent({
  name: 'SubscribeRecordTable',
  components: {
    Tag,
  },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  setup (props) {
    const router = useRouter()
    const displayData = computed(() => {
      return props.tableData.map(i => {
        const snapshot = get(i, 'snapshot')
        return {
          id: get(i, 'id'),
          name: get(i, 'memberName') || '-',
          phone: get(i, 'memberPhone') || '-',
          status: get(i, 'status'),
          failRecord: get(i, 'failRecord') ? `${get(i, 'failRecord')} 筆` : null,
          planName: get(snapshot, 'name') || '-',
          combineName: get(snapshot, 'planEquityPack.equityPack.name') || '-',
        }
      })
    })
    const selectedStatus = (status, attr) => {
      return get(subscribeOrderStatusConfig[status], attr)
    }
    const onRowView = (row) => {
      router.push({ name: 'SubscribeRecordEdit', params: { id: row.id } })
    }

    return {
      get,
      displayData,
      onRowView,
      selectedStatus,
    }
  },
})
</script>

<style scoped lang="postcss">

</style>
