<template>
  <main class="member-shop-order-manage">
    <PageTitle title="訂閱紀錄" btn="匯出" @btnClick="showExportOptions = true" />
    <div class="filters-wrapper">
      <div class="filter-row">
        <p class="label">篩選</p>
        <BaseElSelect
          v-model="search.status"
          class="test2"
          clearable
          placeholder="訂單狀態"
          @change="refresh(true)"
          @clear="refresh(true)"
        >
          <BaseElSelectOption
            v-for="item in subscribeOrderStatusConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
        <BaseElSelect
          v-model="search.hasFailRecord"
          class="test2"
          clearable
          placeholder="是否存在扣款失敗紀錄"
          @change="refresh(true)"
          @clear="refresh(true)"
        >
          <BaseElSelectOption
            v-for="item in subscribeOrderPaymentFailStatusConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
      </div>
      <div class="filter-row">
        <p class="label">搜尋</p>
        <div class="search-row">
          <div class="flex gap-[8px]">
            <BaseElInput
              v-model="search.planName"
              class="test2"
              placeholder="訂閱方案名稱"
              clearable
              @clear="refresh(true)"
              @keypress.enter.native="refresh(true)"
            >
              <i
                slot="suffix"
                class="el-input__icon el-icon-search"
                @click="refresh(true)"
              />
            </BaseElInput>
            <BaseElInput
              v-model="search.equityPackName"
              class="test2"
              placeholder="品項組合名稱"
              clearable
              @clear="refresh(true)"
              @keypress.enter.native="refresh(true)"
            >
              <i
                slot="suffix"
                class="el-input__icon el-icon-search"
                @click="refresh(true)"
              />
            </BaseElInput>
          </div>
          <div class="flex gap-[8px]">
            <BaseElInput
              v-model="search.memberPhone"
              class="test2"
              placeholder="會員手機"
              clearable
              @clear="refresh(true)"
              @keypress.enter.native="refresh(true)"
            >
              <i
                slot="suffix"
                class="el-input__icon el-icon-search"
                @click="refresh(true)"
              />
            </BaseElInput>
            <BaseElInput
              v-model="search.memberName"
              class="test2"
              placeholder="會員名稱"
              clearable
              @clear="refresh(true)"
              @keypress.enter.native="refresh(true)"
            >
              <i
                slot="suffix"
                class="el-input__icon el-icon-search"
                @click="refresh(true)"
              />
            </BaseElInput>
          </div>
        </div>
      </div>
    </div>
    <SubscribeRecordTable
      v-loading="loading.table"
      :tableData="tableData"
    />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
    <ExportOptionsDialog
      v-if="showExportOptions"
      allRange
      @close="showExportOptions = false"
      @export="prepareExport"
    />
    <!-- <ExportDialog
      v-if="showExportDialog"
      :inProgress="exportting"
      :isError="exportError"
      :percentage="exportPercentage"
      :data="exportData"
      :total="exportTotal"
      @close="resetExport"
    /> -->
    <ExportDialog
      v-if="exportState.modal"
      title="匯出訂閱紀錄"
      :inProgress="exportState.exportting"
      :isError="exportState.error"
      :content="exportState.content"
      :percentage="totalPercentege"
      @close="resetExport"
    />
  </main>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, computed, onActivated } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
// import MemberSearch from '@/components/Search/MemberSearch.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
// import CustomFlowSelect from '@/components/Select/CustomFlowSelect.vue'
import SubscribeRecordTable from './components/SubscribeRecordTable.vue'
// import { orderStatusConfig, orderPaymentStatusConfig, orderPaymentTypeConfig } from '@/config/memberShop'
import { subscribeOrderStatusConfig, subscribeOrderPaymentFailStatusConfig, subscribeOrderPaymentStatusConfig, orderOrigins } from '@/config/subscribe'
import { useTable } from '@/use/table'
import store from '@/store'
// import { GetMemberShopOrder, GetMemberShopOrderCount } from '@/api/memberShop'
import { GetSubscribeOrder, GetSubscribeOrderCount } from '@/api/subscribe'
import { get, flatten, isBoolean } from 'lodash'
import { ExportExcel } from '@/utils/excel'
import { formatDate } from '@/utils/date'
// import { useCustomFlow } from '@/use/useCustomFlow'
import { useExport } from '@/use/export'
import dayjs from '@/lib/dayjs'

export default defineComponent({
  name: 'SubscribeRecordList',
  components: {
    SubscribeRecordTable,
    // MemberSearch,
    PageTitle,
    ExportOptionsDialog,
    ExportDialog,
  },
  setup (props) {
    const { tableData, tableOptions, tableDataCount, loading, pageStartIndex } = useTable()
    const { exportState, resetExport, getExportData, totalDataCount, totalPercentege } = useExport()

    const shopId = computed(() => store.getters.shop)
    // TODO: 欄位 mapping
    const search = reactive({
      member: null,
      status: null,
      hasFailRecord: null,
      planName: null,
      equityPackName: null,
      memberPhone: null,
      memberName: null,
    })
    const showExportOptions = ref(false)
    const showExportDialog = ref(false)

    const getSubscribeOrder = async (payload) => {
      const [res, err] = await GetSubscribeOrder(payload)
      if (err) return window.$message.error(err)
      tableData.value = res
    }
    const getSubscribeOrderCount = async (payload) => {
      const [res, err] = await GetSubscribeOrderCount(payload)
      if (err) return window.$message.error(err)
      tableDataCount.value = res.count
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        sortBy: 'createdAt',
        sortType: 'DESC',
        status: search.status || undefined,
        hasFailRecord: isBoolean(search.hasFailRecord) ? search.hasFailRecord : undefined,
        planName: search.planName || undefined,
        equityPackName: search.equityPackName || undefined,
        memberPhone: search.memberPhone || undefined,
        memberName: search.memberName || undefined,
      }
      await Promise.allSettled([
        getSubscribeOrder(payload),
        getSubscribeOrderCount(payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await Promise.all([
        getTableData(),
      ])
      loading.table = false
    }
    const prepareExport = async ({ all, range }) => {
      showExportOptions.value = false
      exportState.modal = true
      exportState.exportting = true

      const payload = {
        shopId: shopId.value,
        start: 0,
        sortBy: 'createdAt',
        sortType: 'DESC',
        status: search.status || undefined,
        hasFailRecord: isBoolean(search.hasFailRecord) ? search.hasFailRecord : undefined,
        planName: search.planName || undefined,
        equityPackName: search.equityPackName || undefined,
        memberPhone: search.memberPhone || undefined,
        memberName: search.memberName || undefined,
        startDate: get(range, '[0]') ? dayjs(get(range, '[0]')).toDate() : undefined,
        endDate: get(range, '[0]') ? dayjs(get(range, '[1]')).toDate() : undefined,
      }

      const [res, err] = await GetSubscribeOrderCount(payload)
      if (err) window.$message.error(err)
      exportState.dataCount = [res.count]

      if (!totalDataCount.value) {
        window.$message.warning('沒有資料可以匯出')
        return resetExport()
      }
      try {
        await getExportData({ stage: 0, fetchAPI: GetSubscribeOrder, payload })
        await formatExportData(exportState.exportData)
      } catch (error) {
        console.log(error)
        window.$message.error(error)
        exportState.exportting = false
        exportState.error = true
      }
    }
    const formatExportData = async (exportData) => {
      exportData = flatten(exportData)
      const data = []
      for (const item of exportData) {
        for (const subItem of item.payments) {
          const row = await formatRecordData(item, subItem)
          data.push(row)
        }
      }
      const fileName = '訂閱紀錄'
      exportState.success = true
      exportState.content = '匯出完成'
      ExportExcel(data, fileName, fileName)
    }
    const formatRecordData = async (order, payment) => {
      return {
        訂單編號: get(order, 'orderNo') || '-',
        扣款紀錄編號: get(payment, 'paymentNumber') || '-',
        會員姓名: get(order, 'memberName') || '-',
        會員手機: get(order, 'memberPhone') || '-',
        訂閱方案名稱: get(order, 'plan.name') || '-',
        訂單狀態: get(order, 'status') ? orderStatus(get(order, 'status'), 'label') : '-',
        首次訂閱日期: get(order, 'startDate') ? formatDate(get(order, 'startDate')) : '-',
        // 本次續約起始日: get(order, 'currentRenewDate') ? formatDate(get(order, 'currentRenewDate')) : '-',
        // 下次續約起始日: get(order, 'nextRenewDate') ? formatDate(get(order, 'nextRenewDate')) : '-',
        本次訂閱終止日: get(order, 'cancelDate') ? formatDate(get(order, 'cancelDate')) : '-',
        訂閱來源: get(order, 'orderFrom') ? orderOriginStatus(get(order, 'orderFrom'), 'label') : '-',
        收款子帳戶: get(order, 'receiveAccount') || '-',
        會員付款方式: get(order, 'paymentType') || '-',
        已收款金額總和: get(order, 'paidAmount') || '-',
        扣款紀錄開始日: get(order, 'startDate') ? formatDate(get(order, 'startDate')) : '-',
        扣款紀錄結束日: get(order, 'endDate') ? formatDate(get(order, 'endDate')) : '-',
        // 扣款日: get(payment, 'paymentDate') ? formatDate(get(payment, 'paymentDate')) : '-',
        扣款金額: get(payment, 'paymentAmount') || '-',
        退款金額: get(payment, 'refundAmount') || '-',
        扣款狀態: get(payment, 'status') ? orderPaymentStatus(get(payment, 'status'), 'label') : '-',
        扣款方式: get(payment, 'paymentType') || '-',
        退款方式: get(payment, 'refundType') || '-',
        退款日期: get(payment, 'refundDate') ? formatDate(get(payment, 'refundDate')) : '-',
        預定扣款日: get(payment, 'expectedOrderPaymentDate') ? formatDate(get(payment, 'expectedOrderPaymentDate')) : '-',
        實際扣款日: get(payment, 'paymentDate') ? formatDate(get(payment, 'paymentDate')) : '-',
      }
    }
    const orderStatus = (status, attr) => {
      return get(subscribeOrderStatusConfig[status], attr)
    }
    const orderPaymentStatus = (status, attr) => {
      return get(subscribeOrderPaymentStatusConfig[status], attr)
    }
    const orderOriginStatus = (status, attr) => {
      return get(orderOrigins[status], attr)
    }

    onMounted(async () => {
      await refresh()
    })
    onActivated(async () => {
      await refresh()
    })

    return {
      tableData,
      tableOptions,
      loading,
      refresh,
      search,
      tableDataCount,
      showExportOptions,
      showExportDialog,
      prepareExport,
      resetExport,
      subscribeOrderStatusConfig,
      subscribeOrderPaymentFailStatusConfig,
      exportState,
      totalPercentege,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .test.el-select,
::v-deep .test .el-input {
  max-width: 196px !important;
}
::v-deep .test2.el-select,
::v-deep .test2.el-input,
::v-deep .test2 .el-input,
::v-deep.test2.el-input__inner {
  width: 100% !important;
  max-width: 400px !important;
}
.filters-wrapper {
  @apply flex flex-col gap-[20px] mb-[20px];
}
.filter-row {
  @apply flex items-center gap-[8px];
}

.filter-row .label {
  @apply flex-shrink-0;
}

.search-row {
  @apply flex w-full flex-col gap-[8px];
}
</style>
